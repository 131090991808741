import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../components/layout'
import Seo from '../components/plumbing/Head'
import CTA from '../components/Mailchimp'
import { Trails } from '../components/Trails'

const things = ['artists', 'designers', 'creators', 'curators', 'storytellers', 'educators']

const CreatorPage = () => (
  <Layout>
    <StaticImage
      src="../assets/images/creator.jpeg"
      alt="creator"
      style={{ marginTop: '8rem', height: '90%' }}
    />
    <div
      className="ten-central creator-heading"
      style={{
        position: 'relative',
        marginLeft: '9rem',
        bottom: '12rem',
        width: '80%',
      }}
    >
      <Trails text="Elysium is a the real-time embodied AR design environment for" array={things} />
    </div>
    <Seo title="Creator Residency Program" description="" />
    <section className="ten-central" style={{ marginBottom: '9rem', height: '100%' }}>
      <div className="ten-central">
        <p></p>
        <h1>Creator Residency Program</h1>
        <p>
          ELYSIUM is the interactive design tool for creators looking to create living digital experiences in
          the wild. At Mythologi we want to empower you to unleash your imagination to experiment, play, and
          innovate with this new medium. With ELYSIUM we want you to redefine storytelling through immersive
          experinences that engage people in spaces.
        </p>
        <p>
          In building a sustainable creative community, we are launching our first <b>Creator’s Residency</b>{' '}
          in the spring of 2022. We invite artists across all disciplines, and across the globe, to
          participate in a 2-week online program that will enable you to bring your work to life in the real
          world with ELYSIUM. Applicants will have access to our private Beta and will have the opportunity
          meet and collaborate with other international artists to design, build, and exhibit interconnected
          interactive AR experiences through our creative partners in the year.{' '}
        </p>
        <p>No coding or programming experience needed. </p>
        <p>Application dates and further details will be announced in early April 2022. </p>
        <CTA />
      </div>
    </section>
  </Layout>
)

export default CreatorPage
